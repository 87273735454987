import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../../navigation/navbar";

const Home = (props) => {
  return (
    <>
      <div className="pagefade">
        <NavBar />
        <div>Page with signed in</div>
        <p>
          <button onClick={() => props.SignOut()}>Sign Out</button>
        </p>
      </div>
    </>
  );
};

export default Home;
