import React from "react";
import { useParams, Link } from "react-router-dom";
const Productdetails = () => {
  const param = useParams();
  return (
    <div className="pagefade">
      <header>
        <div className="collapse bg-success d-sm-none" id="navbarHeader">
          <div className="container ">
            <div className="row">
              <div className="col-sm-8 col-md-7 pt-2 bg-light ">
                <h4 className="text-success">About</h4>
                <p className="text-mued text-success">
                  Add some information about the album below, the author, or any
                  other background context. Make it a few sentences long so
                  folks can pick up some informative tidbits. Then, link them
                  off to some social networking sites or contact information.
                </p>
              </div>
              <div className="col-sm-4 offset-md-1  "></div>
            </div>
          </div>
        </div>
        <div className="navbar navbar-expad-sm navbar-dark bg-success shadow-sm">
          <div className="container">
            <Link
              to=".."
              relative="path"
              className="navbar-brand d-flex align-items-center "
            >
              <i className="bi bi-camera pe-2 h3 my-auto"></i>
              Go Back
            </Link>
            {/* <strong>Go back</strong> */}

            {/* some insertion here */}

            <div className="collapse navbar-collapse "></div>
            {/* insertion ends */}
          </div>
        </div>
      </header>

      {/* <p>{`https://marjara.net/tmp/${param.prodId}`}</p> */}
      <p>
        {/* <Link to=".." relative="path">
          back
        </Link> */}
      </p>
      <video
        className=""
        width="100%"
        height="auto"
        playsInline={true}
        muted={true}
        autoPlay={true}
        loop
        controls
        // onLoad={(event) => event.target.play()}
        // onMouseOver={(event) => event.target.play()}
        // onMouseOut={(event) => event.target.pause()}
      >
        <source
          src={`https://marjara.net/tmp/${param.prodId}`}
          type="video/mp4"
        />{" "}
      </video>
    </div>
  );
};

export default Productdetails;
