import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div>
      <p>Error page</p>
      <button>
        <Link to={".."} relative="path">
          go back
        </Link>
      </button>
    </div>
  );
};

export default Error;
