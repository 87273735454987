import React from "react";
import { Link, useNavigate, useLoaderData } from "react-router-dom";

const products = [
  { id: "P1", title: "product 1" },
  { id: "P2", title: "product 2" },
  { id: "P3", title: "product 3" },
];
const ProductsPage = (props) => {
  const navigate = useNavigate();
  const resData = useLoaderData();

  return (
    <>
      <div className="pagefade">
        <div>product page</div>
        <br />
        <ul>
          {products.map((item, index) => {
            return (
              <li key={item.id}>
                <Link to={item.id}>{item.title}</Link>
              </li>
            );
          })}
        </ul>

        <p>
          <Link to="/">Home</Link>
        </p>
        <button
          onClick={() => {
            // console.log(resData);
          }}
        >
          test
        </button>
        <button onClick={() => props.onClick()}>prop</button>
      </div>
    </>
  );
};

export default ProductsPage;

export function LoaderEvent() {
  return [({ id: "test1" }, { id: "test2" })];
}
