import React from "react";
import { Link, useNavigate, useLoaderData } from "react-router-dom";

// <Link
// className="nav-link text-white fw-bold d-flex align-items-center "
// aria-current="page"
// href="#"
// to={"aa"}
// >
// Videos
// </Link>

const Videocard = (props) => {
  return (
    <div className="col">
      <div className="card shadow-sm">
        <Link to={props.data.filename}>
          <video
            className=""
            width="100%"
            height="auto"
            playsInline={true}
            muted={true}
            autoPlay={false}
            loop
            onLoad={(event) => event.target.play()}
            onMouseOver={(event) => event.target.play()}
            onMouseOut={(event) => event.target.pause()}
          >
            <source src={props.data.src} type="video/mp4" />{" "}
          </video>
        </Link>

        <div className="card-body">
          <div className="card-text">
            <ul className="list-unstyled fw-light">
              <li>
                <span className="d-inline-block col-12  text-truncate">
                  <b>Title :</b> tconsectetuer secte adipis g elit
                </span>
              </li>
              <li>
                <span className="d-inline-block col-12  text-truncate">
                  <b>Author :</b> consectetuer adipisc ing elitconsectetuer
                  adipiscing elit
                </span>
              </li>
            </ul>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-outine-none shadow-none"
              >
                <i className="bi bi-hand-thumbs-up h5"></i>
              </button>

              <button
                type="button"
                className="btn btn-sm btn-outine-none shadow-none"
              >
                <i className="bi bi-eye h5 mt-auto"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outine-none shadow-none"
              >
                <i className="bi bi-info-lg h5 mt-auto"></i>
              </button>
            </div>
            <small className="text-muted">9 mins</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videocard;
