import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/app/home";
import Products, { LoaderEvent } from "./pages/app/products";
import RootLayout from "./pages/root/root";
import ErrorPage from "./pages/errors/error";
import Productdetails from "./pages/app/productdetails";
import Login from "./pages/Auth/login";
import ShowData from "./reducer/showreducedate";
import UserContextProvider from "./context/contextprovider";
import SampleContextPage from "./context/samplepage";
import Snackbar from "./context/snackbar";
import Recaptcha from "./utils/recaptcha";
import Media from "./pages/media/media";
import Corousal from "./pages/corousal/corousal";
import Slide1 from "./pages/corousal/slide1";
const App = () => {
  const [login, setLogin] = useState();
  const router = createBrowserRouter([
    {
      path: "/",
      element: login ? undefined : <RootLayout />,
      errorElement: <ErrorPage />,

      children: login && [
        { index: true, element: <Home SignOut={() => setLogin(false)} /> },
        {
          path: "products",
          element: <Products onClick={() => console.log("clicked")} />,
          loader: LoaderEvent,
        },
        { path: "products/:prodId", element: <Productdetails /> },
      ],
    },
    {
      path: "/login",
      element: (
        <Login
          SignIn={() => {
            console.log("signing");
            setLogin(true);
          }}
        />
      ),
    },
    { path: "/:prodId", element: <Productdetails /> },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
      {/* <UserContextProvider>
        <SampleContextPage />
        <Snackbar />
      </UserContextProvider> */}
      {/* <ShowData /> */}
      {/* <Recaptcha /> */}
      {/* <Media /> */}
      {/* <Slide1 /> */}
      {/* <Corousal /> */}
    </div>
  );
};

export default App;
