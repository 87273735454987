import React from "react";
import { Link } from "react-router-dom";
const Login = (props) => {
  return (
    <div className={"pagefade"}>
      this is login page
      <p>
        <button onClick={() => props.SignIn()}>
          <Link to={"/"}>Sign in</Link>
        </button>
      </p>
      <p>
        <button>
          <Link to={".."} relative="path">
            Cancel
          </Link>
        </button>
      </p>
    </div>
  );
};
export default Login;
