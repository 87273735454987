import React from "react";
import { Link, useNavigate, useLoaderData } from "react-router-dom";

// import "./corousal.css";
// import { Slide1, Slide2 } from "./slide1";
// import VDO from "../../vdo/deer.mp4";
import VideoCard from "./videocard";
const RootLayout = () => {
  const MediaSource = [
    { src: "https://marjara.net/tmp/covid5.mp4", filename: "covid5.mp4" },
    { src: "https://marjara.net/tmp/covid2.mp4", filename: "covid2.mp4" },
    { src: "https://marjara.net/tmp/covid4.mp4", filename: "covid4.mp4" },
    { src: "https://marjara.net/tmp/covid3.mp4", filename: "covid3.mp4" },
    { src: "https://marjara.net/tmp/covid5.mp4", filename: "covid5.mp4" },
    { src: "https://marjara.net/tmp/covid6.mp4", filename: "covid6.mp4" },
    { src: "https://marjara.net/tmp/covid7.mp4", filename: "covid7.mp4" },
    { src: "https://marjara.net/tmp/covid2.mp4", filename: "covid2.mp4" },
    { src: "https://marjara.net/tmp/covid4.mp4", filename: "covid4.mp4" },
  ];

  function PopulateVideoCards() {
    return MediaSource.map((obj, index) => {
      return <VideoCard data={obj} key={index} />;
    });
  }
  function MenuItems() {
    return (
      <ul className="navbar-nav ms-auto ">
        <li className="nav-item ">
          <a
            className="nav-link text-white fw-bold d-flex align-items-center "
            aria-current="page"
            href="#"
            onClick={() => {
              console.log("clicked");
            }}
          >
            Videos
          </a>
        </li>

        <li className="nav-item ">
          <a
            className=" p nav-link h d-flex align-items-center fw-bold text-white"
            href="#"
          >
            Pictures
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link h d-flex align-items-center fw-bold text-white"
            href="#"
          >
            Tools
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link h d-flex align-items-center fw-bold text-white"
            href="#"
          >
            Upload
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link h d-flex align-items-center fw-bold text-white"
            href="#"
          >
            Sign in
          </a>
        </li>
      </ul>
    );
  }
  return (
    <div>
      {/* <div className="home-panel-container "><Slide1 /></div> */}

      <header>
        <div className="collapse bg-success d-sm-none" id="navbarHeader">
          <div className="container ">
            <div className="row">
              <div className="col-sm-8 col-md-7 pt-2 bg-light ">
                <h4 className="text-success">About</h4>
                <p className="text-mued text-success">
                  Add some information about the album below, the author, or any
                  other background context. Make it a few sentences long so
                  folks can pick up some informative tidbits. Then, link them
                  off to some social networking sites or contact information.
                </p>
              </div>
              <div className="col-sm-4 offset-md-1  ">{MenuItems()}</div>
            </div>
          </div>
        </div>
        <div className="navbar navbar-expand-sm navbar-dark bg-success shadow-sm ">
          <div className="container  ">
            <a href="#" className="navbar-brand d-flex align-items-center ">
              <i className="bi bi-camera pe-2 h3 my-auto"></i>

              <strong>Compedium</strong>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarHeader"
              aria-controls="navbarHeader"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* some insertion here */}

            <div className="collapse navbar-collapse ">{MenuItems()}</div>
            {/* insertion ends */}
          </div>
        </div>
      </header>
      <main>
        <section className="py-5 text-center container">
          <div className="row py-lg-3">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light">Health Promotion Compedium</h1>
              <p className="lead text-muted">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Maecenas porttitor congue massa. Fusce posuere, magna sed
                malesuada libero, sit amet commodo magna eros quis urna.
              </p>
              <div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text bg-white border-end-0"
                    id="basic-addon1"
                  >
                    <i className="bi bi-search "></i>
                  </span>
                  <input
                    type="search"
                    className="form-control border-start-0 shadow-none"
                    placeholder="search by title, author, description"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                  <span className="input-group-text py-0" id="basic-addon2">
                    <button className="border-0 bg-light">search</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className=" pt-5 bg-light">
          <div className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
              {PopulateVideoCards()}
            </div>
          </div>
          <footer className="mt-5">
            <nav
              aria-label="Page navigation "
              className="d-flex justify-content-end me-5"
            >
              <ul className="pagination">
                <li className="page-item ">
                  <a className="page-link border-0 disabled" href="#">
                    Previous
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link border-0" href="#">
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link border-0" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link border-0" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link border-0" href="#">
                    Next
                  </a>
                </li>
              </ul>
            </nav>
          </footer>
        </div>
      </main>

      <footer className="text-muted py-5">
        <div className="container">
          <p className="float-end mb-1">
            <a href="#">Back to top</a>
          </p>
          <p className="mb-1">This website is powered by &copy; PSI</p>

          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-outine-none shadow-none"
            >
              <i className="bi bi-facebook h4"></i>
            </button>

            <button
              type="button"
              className="btn btn-sm btn-outine-none shadow-none"
            >
              <i className="bi bi-twitter h4 text-info"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outine-none shadow-none"
            >
              <i className="bi bi-instagram h4"></i>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outine-none shadow-none"
            >
              <i className="bi bi-envelope h4"></i>
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default RootLayout;
